.react-grid-item.react-grid-placeholder {
    background-color: lime;
    z-index: 1;
}

.react-grid-item {
    background-color: teal;
    z-index: 2;
}

.circle-picker span div span div[title~="#ffffff"]{
    border: 1px solid #999;
}

.white-selected span div span div[title~="#ffffff"]{
    box-shadow: #999 0 0 0 5px inset, 0 0 5px #999 !important;
}