.MuiDataGrid-cell--withRenderer {
    padding: 0 !important;
}
.MuiDataGrid-cell[data-field="qaíntity"] {
    display: flex !important;
    justify-content: center !important;
}

.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell {
    display: flex !important;
    justify-content: center !important;
}

.surplus {
    background-color: #4caf50 !important;
}

.shortage {
    background-color: #f44336 !important;
}