.MuiDataGrid-cell--withRenderer {
    padding: 0 !important;
}
.MuiDataGrid-cell[data-field="edit"] {
    display: flex !important;
    justify-content: center !important;
    border: 1px solid #ccc;
}
.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell {
    display: flex !important;
    justify-content: center !important;
}
.checkbox {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background-color: #ff71bc !important;
}
