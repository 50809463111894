.draggable-demo {
    padding: 20px;
}
.rc-tree-title {
    margin-left: 16px;
    vertical-align: top;
}
.rc-tree-treenode{
    display: flex;
    justify-content: space-between;

}
.editNodeToolbar {
    display: flex;
    flex-direction: row;
}

.groupDialog {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar {
    display: none;  /* Safari and Chrome */
}